
import { computed, defineComponent, nextTick, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessage } from 'element-plus/lib/components'
import { debounce } from 'throttle-debounce'

import httpRequest from '@/service'
import { IComObj } from '@/global/types'
import { theme } from '@/theme/config/theme.config'
import { isCollect } from '@/utils/collect'
import usePopoverMenu from '@/utils/usePopoverMenu'
import { jumpFn, jumpNewPage } from './utils' // 详情页链接跳转新页面

import BreadCrumb from '@/components/breadCrumb/index.vue'
import PopoverMenu from '@/components/PopoverMenu/index.vue'
import Rules from './components/MainView/Rules.vue'
import FoldPanel from './components/MainView/FoldPanel.vue'
import TagPanel from './components/MainView/TagPanel.vue'
import SearchInput from './cpns/searchInput.vue'
import RightPane from './cpns/rightPane.vue'
import { BASE_URL } from '@/service/request/config'
// import {QuillEditor} from '@vueup/vue-quill'
// import ckeditor5 from '@/deprecated/ckeditor5/index.vue'
// import '@vueup/vue-quill/dist/vue-quill.snow.css'

export default defineComponent({
  name: 'DetailPage',
  components: {
    Rules,
    SearchInput,
    BreadCrumb,
    RightPane,
    FoldPanel,
    TagPanel,
    // QuillEditor,
    // ckeditor5,
    PopoverMenu
  },
  setup() {
    const { popShow, popLeft, popTop, showPop,handleCopy,handleSearch } = usePopoverMenu((value)=>{
      doParamSearch('全文','searchArtical',value)
    })
    const loading = ref(true)
    const route = useRoute()
    const router = useRouter()
    const matchLink = (list) => {
      // for (const key in detailData.value) {
        // if(['cpwsAlCpyz','cpwsAlGlsy','cpwsAlJbaq','cpwsAlCpjg','cpwsAlCply'].includes(key)){
        //   list.forEach(item => {
        //     detailData.value[key] = detailData.value[key].replaceAll(item.name,'<a target="_blank" href="#/DetailPage?id='+item.id+'">'+item.name+'</a>')
        //   })
        // }
      // }
    }
    // 纯净版标准版切换
    const pureState = reactive({
      id: '0',
      selectValue: '标准版',
      selectOptions: [
        { label: '标准版', id: '0' },
        { label: '纯净版', id: '1' },
        // { label: '标签版', id: '2' }
      ],
      change: (val: string) => {
        pureState.id = val
        const aa = fontSizeRef.value.querySelectorAll('.relate-link')
        if (pureState.id === '1' && aa) {
          aa.forEach((a: any) => {
            a.onclick = null
            a.classList.add('pure-text')
          })
        } else {
          jumpNewPage(fontSizeRef.value, '.relate-link', router)
        }
      }
    })
    // 字体大小切换
    const fontSizeRef = ref()
    const fontSizeState = reactive({
      curTitleSize: 22,
      curSize: 20, // 分段16-24 单篇18-26
      sizeChange: debounce(500, (type: string) => {
        // 是否整篇导入
        const isSingle = detailPageList.value.details.length == 1
        if (type === 'smaller' && fontSizeState.curSize == (isSingle? 18: 16)) {
          ElMessage({
            message: '已经缩小到最小',
            type: 'warning'
          })
          return
        }
        if (type === 'bigger' && fontSizeState.curSize == (isSingle? 26: 24)) {
          ElMessage({
            message: '已经放大到最大',
            type: 'warning'
          })
          return
        }
        if(type === 'smaller'){
          fontSizeState.curTitleSize -= 2
          fontSizeState.curSize -= 2
        }else{
          fontSizeState.curTitleSize += 2
          fontSizeState.curSize += 2
        }
        if(fontSizeRef.value.querySelectorAll('h4')[0]){
          fontSizeRef.value.querySelectorAll('h4')[0].style.fontSize = fontSizeState.curTitleSize + 'px'
        }
        fontSizeRef.value.style.fontSize = fontSizeState.curSize + 'px'
        // 如果是整篇导入
        if(isSingle){
          // 取出文字内容
          const content = detailPageList.value.details[0].name
          // 把font-size的数值筛出来
          // const numList = content.match(/(?<=font-size\s*:).*?(?=pt)/g)
          const numList = content.match(new RegExp('(?<=font-size\s*:).*?(?=pt)'),'g')
          // 把font-size的数值去掉,然后拆成数组
          // const strList = content.split(/(?<=font-size\s*:).*?(?=pt)/)
          const strList = content.split(new RegExp('(?<=font-size\s*:).*?(?=pt)'))
          // 数值+1或-1后重新塞回数组
          numList.forEach((item,index)=>{
            strList[index] += item*1 + (type === 'smaller' ? -2: 2)
          })
          // 赋值文字内容
          detailPageList.value.details[0].name = strList.join('')
        }
      })
    })

    // 背景颜色切换
    const skinState = reactive<IComObj>({
      data: theme,
      colorData: [],
      change: (item: IComObj) => {
        for (const [key, val] of Object.entries(item)) {
          document.documentElement.style.setProperty(
            '--' + `${key}`,
            String(val)
          )
        }
      },
      getData: () => {
        theme.forEach((i: IComObj) => {
          skinState.colorData.push(i.BackColor)
        })
      }
    })
    skinState.getData()

    //下载
    const dowmloadLink = ref('')
    // 收藏
    const isFavor = debounce(500, () => {
      isFavorValue.value = !isFavorValue.value
      isCollect(isFavorValue.value, currentID.value)
    })
    // 跳转法律图谱
    const toKnowledgeMap = () => {
      const { href } = router.resolve({
        name: 'KnowledgeMap',
        query: {
          id: currentID.value
        }
      })
      window.open(href, '_blank')
    }
    // 附件列表，下载附件
    const fileList = ref<any[]>([])
    // 左侧详情页
    const isFavorValue = ref(false)
    const detailPageList = ref<IComObj>({})
    const currentID = ref('')
    const currentName = ref('')
    const detailPageListApi = () => {
      if (!route.query || !route.query.id) {
        return
      }
      httpRequest
        .post({
          url: 'detailPageList',
          params: { id: route.query.id }
        })
        .then((res) => {
          detailPageList.value = res.data
          if(detailPageList.value.name){
            const title = document.getElementsByTagName('title')[0]
            title.innerHTML = detailPageList.value.name
          }
          fileList.value = res.data.files || []
          dowmloadLink.value = BASE_URL + `/common/download/realtime?ids=${res.data.id}`
          currentID.value = res.data.id
          currentName.value = res.data.name
          // 给条例里可以跳转的链接添加点击事件
          nextTick(() => {
            jumpNewPage(fontSizeRef.value, '.relate-link', router)
            getBLength()
          })
          if (route.query.paraId) {
            nextTick(() => {
              handledropItemClick(route.query.paraId)
            })
          }
          loading.value = false
          // 判断是否收藏
          httpRequest.post({
            url: 'judgeCollection',
            params: { lawVersionId: route.query.id }
          }).then(res2=>{
            isFavorValue.value = res2.data
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }
    // 是否为中央文件
    const isZywj = computed(() => {
      return detailPageList.value.properties?.find((item: any) => item.name == '效力级别')?.value == '中央文件'
    })
    const matchKey = ref(0)
    const searchValue = ref<string[]>([])
    const matchArrLength = ref(0)
    const matchIndex = ref(0)

    const getBLength = () => {
      matchKey.value = 0
      matchArrLength.value =
        document.querySelector('.regulation-wrap')?.querySelectorAll('b')
          .length || 0
      matchChange(matchKey.value)
    }

    const changeSearch = (strArr: string[]) => {
      searchValue.value = strArr
      nextTick(() => {
        jumpNewPage(fontSizeRef.value, '.relate-link', router)
        getBLength()
      })
    }

    const matchChange = (ind: number) => {
      matchIndex.value = ind
    }

    const handledropItemClick = (id: any) => {
      const returnEle: HTMLElement = document.getElementById(id) as HTMLElement
      if (returnEle) {
        if (returnEle.nextElementSibling?.attributes[0].value) {
          matchKey.value =
            Number(returnEle.nextElementSibling?.attributes[0].value) - 1
        } else {
          matchKey.value = -1
        }

        matchArrLength.value =
          document.querySelector('.regulation-wrap')?.querySelectorAll('b')
            .length || 0
        matchChange(matchKey.value)
        document.documentElement.scrollTop = returnEle.offsetTop
      }
    }

    watch(
      () => route.query.id,
      () => {
        detailPageListApi()
      }
    )
    detailPageListApi()


    const lawSummary = reactive({
      loading: false,
      currentId: '',
      data: {
        lawVersionId: undefined,
        lawName: undefined,
        publishOrgName: undefined,
        timelinessName: undefined,
        lawNumber: undefined,
        effectLevelName: undefined,
        publishDate: undefined,
        implDate: undefined
      },
      clearData() {
        this.data = {
          lawVersionId: undefined,
          lawName: undefined,
          publishOrgName: undefined,
          timelinessName: undefined,
          lawNumber: undefined,
          effectLevelName: undefined,
          publishDate: undefined,
          implDate: undefined
        }
      },
      loadData() {
        if (!this.currentId) {
          return
        }
        let lawVersionId = this.currentId
        this.loading = true
        this.clearData()
        if (!lawVersionId) {
          return
          this.loading = false
        }
        httpRequest
          .get({
            url: 'lawSummary',
            params: {
              lawVersionId: lawVersionId
            }
          })
          .then((res: any) => {
            this.data = res.data
            this.loading = false
          })
          .catch((err) => {
            ElMessage({
              message: '法律摘要加载失败',
              type: 'error'
            })
            this.loading = false
          })
      }
    })
    lawSummary.clearData()

    const handleLinkHoverSplit = (content: string) => {
      return content.split(/(<span class="relate-link" data-id=.*?<\/span>)/g)
    }

    // const handleLoadLawSummary = (content: string) => {
    //   let matches = content.match(/data-id="([0-9]*)"/)
    //   if (matches && matches.length > 1) {
    //     lawSummary.loadData(matches?.[1] ?? '')
    //   }
    // }

    const handleTitle = () => {
      if (lawSummary.currentId) {
        jumpFn(lawSummary.currentId, router)
      }
    }

    const notitle = computed(() => {
      if ((detailPageList.value.properties || []).some((item: any) => item.name === '效力级别' && (
        item.value === '中央文件' || item.value === '政策性文件' || item.value.includes('规范性文件')
      ))) {
        return true
      } else {
        return false
      }
    })
    // 点击关键词查询
    const doParamSearch = (name,field,value) => {
      const routeData = router.resolve({ path: '/SearchList', query: { name,field,value } })
      window.open(routeData.href, '_blank')
    }
    return {
      popShow,
      popLeft,
      popTop,
      showPop,
      handleCopy,
      handleSearch,
      notitle,
      loading,
      matchArrLength,
      matchIndex,
      matchKey,
      matchChange,
      detailPageList,
      dowmloadLink,
      isFavor,
      isFavorValue,
      searchValue,
      changeSearch,
      fontSizeRef,
      fontSizeState,
      skinState,
      pureState,
      currentID,
      toKnowledgeMap,
      fileList,
      isZywj,
      lawSummary,
      handleLinkHoverSplit,
      handleTitle,
      matchLink,
      doParamSearch,
      REQUIRE_LOGIN:process.env.VUE_APP_REQUIRE_LOGIN
    }
  }
})
